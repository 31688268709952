import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

/**
 * Store provides variables that are exposed application wide. It also allows for changing these variables through explicit actions
 * to prevent accidently data corruption. Normalizing the data is generally the best approach since it allows for variables to be watched reactively by Vue.
 */
export default new Vuex.Store({
  state: {
    authToken: null,
    serverUrl: process.env.VUE_APP_API_ENDPOINT,
    helpUrl: process.env.VUE_APP_HELP,
    eula: false,
    username: null,
    email: null,
    firstName: null,
    lastName: null,
    accountId: null,
    company: null,
    serverActive: false,
    licenseState: 'ACTIVE',
    licenseMode: 'NORMAL',
  },
  mutations: {
    setToken(state, token) {
      state.authToken = token;
    },
    clearToken(state) {
      state.authToken = null;
    },
    setEula(state, eula) {
      state.eula = eula;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setFirstName(state, firstName) {
      state.firstName = firstName;
    },
    setLastName(state, lastName) {
      state.lastName = lastName;
    },
    setAccountId(state, accountId) {
      state.accountId = accountId;
    },
    setCompany(state, company) {
      state.company = company;
    },
    setServerActive(state, serverActive) {
      state.serverActive = serverActive;
    },
    setLicenseState(state, licenseState) {
      state.licenseState = licenseState;
    },
    setLicenseMode(state, licenseMode) {
      state.licenseMode = licenseMode;
    },
    setUserDetails(state, userDetails) {
      state.username = userDetails.username;
      state.email = userDetails.email;
      state.firstName = userDetails.firstName;
      state.lastName = userDetails.lastName;
      state.accountId = userDetails.accountId;
      state.company = userDetails.company;
    },
    clearUserDetails(state) {
      state.authToken = null;
      state.username = null;
      state.email = null;
      state.firstName = null;
      state.lastName = null;
      state.accountId = null;
      state.company = null;
      state.serverActive = false;
      state.licenseState = 'ACTIVE';
      state.licenseMode = 'NORMAL';
    },
  },
  actions: {
    setToken(context, token) {
      context.commit('setToken', token);
    },
    clearToken(context) {
      context.commit('clearToken');
    },
    setEula(context, eula) {
      context.commit('setEula', eula);
    },
    setUsername(context, username) {
      context.commit('setUsername', username);
    },
    setEmail(context, email) {
      context.commit('setEmail', email);
    },
    setFirstName(context, firstName) {
      context.commit('setFirstName', firstName);
    },
    setLastName(context, lastName) {
      context.commit('setLastName', lastName);
    },
    setAccountId(context, accountId) {
      context.commit('setAccountId', accountId);
    },
    seCompany(context, company) {
      context.commit('setCompany', company);
    },
    setUserDetails(context, userDetails) {
      context.commit('setUserDetails', userDetails);
    },
    setServerActive(context, serverActive) {
      context.commit('setServerActive', serverActive);
    },
    setLicenseState(context, licenseState) {
      context.commit('setLicenseState', licenseState);
    },
    setLicenseMode(context, licenseMode) {
      context.commit('setLicenseMode', licenseMode);
    },
    clearUserDetails(context) {
      context.commit('clearUserDetails');
    },
  },
  getters: {
    getUserDetails(state) {
      return {
        accountId: state.accountId,
        username: state.username,
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        company: state.company,
      };
    },
  },
});
