var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.hideBanner && _vm.licenseMode === "NORMAL",
          expression: "!hideBanner && licenseMode === 'NORMAL'",
        },
      ],
      class: ["chariot-temporary-license-banner", _vm.dynamicBannerClass],
    },
    [
      _c(
        "div",
        { staticClass: "align-self-center m-auto text-nowrap" },
        [
          _c("b", { staticClass: "mr-3" }, [_vm._v(_vm._s(_vm.licenseText))]),
          _vm.licenseState === "TRIAL"
            ? _c("vac", {
                ref: "bannerTimer",
                staticClass: "chariot-license-timer",
                attrs: { "auto-start": false, "left-time": _vm.trialTimer },
                on: { finish: _vm.stopLicense },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "process",
                      fn: function ({ timeObj }) {
                        return _c("span", {}, [
                          _vm._v(
                            _vm._s(`${timeObj.h}:${timeObj.m}:${timeObj.s}`)
                          ),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  1139475868
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.licenseState !== "TRIAL"
        ? _c(
            "BButton",
            {
              staticClass: "chariot-refresh align-self-right m-2",
              attrs: { variant: "outline-danger" },
              on: { click: _vm.startLicense },
            },
            [
              _c("BIconClock", { staticClass: "mr-2" }),
              _vm._v(_vm._s(_vm.$t("license.startLicense")) + " "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }