var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "nav-locked": _vm.lockedNav && _vm.showNav,
        "no-nav": !_vm.showNav,
        "no-transition": _vm.disableTransition,
      },
      attrs: { id: "app" },
    },
    [
      _vm.showNav
        ? _c("SideMenu", {
            on: { logout: _vm.logout, locked: _vm.setNavState },
          })
        : _vm._e(),
      _c(
        "div",
        { attrs: { id: "appWrapper" } },
        [
          _vm.showNav ? _c("LicenseBanner") : _vm._e(),
          _c(
            "BContainer",
            {
              class: { fullscreen: _vm.fullscreen, "px-2": !_vm.fullscreen },
              attrs: { fluid: _vm.fullscreen },
            },
            [
              _c(
                "transition",
                {
                  attrs: { name: "fade", mode: "out-in" },
                  on: {
                    beforeLeave: _vm.beforeLeave,
                    beforeEnter: _vm.beforeEnter,
                    afterEnter: _vm.afterEnter,
                  },
                },
                [_c("routerView")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showFooter
        ? _c(
            "div",
            { staticClass: "text-center mb-3 text-muted chariot-footer" },
            [_vm._v(" Copyright 2020-2024 Cirrus Link Solutions ")]
          )
        : _vm._e(),
      _c("BOverlay", {
        attrs: {
          show: _vm.globalLoading,
          opacity: "0.8",
          "z-index": "10000",
          "no-center": "",
          small: "",
          "no-wrap": "",
          type: "grow",
          "spinner-variant": "primary",
        },
        scopedSlots: _vm._u([
          {
            key: "overlay",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-center chariot-overlay-message" },
                  [
                    _c("h3", { staticClass: "text-primary" }, [
                      _vm._v(_vm._s(_vm.globalLoadingText)),
                    ]),
                    _c("BSpinner", {
                      attrs: {
                        variant: "primary",
                        type: "grow",
                        label: "Spinning",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }