<template>
  <div :class="{ 'nav-locked': lockedNav && showNav, 'no-nav': !showNav, 'no-transition': disableTransition }" id="app">
    <SideMenu @logout="logout" @locked="setNavState" v-if="showNav" />
    <div id="appWrapper">
      <LicenseBanner v-if="showNav"></LicenseBanner>
      <BContainer :class="{ 'fullscreen': fullscreen, 'px-2': !fullscreen }" :fluid='fullscreen'>
        <transition
          name="fade"
          mode="out-in"
          @beforeLeave="beforeLeave"
          @beforeEnter="beforeEnter"
          @afterEnter="afterEnter">
          <routerView/>
        </transition>
      </BContainer>
    </div>
    <div v-if="showFooter" class="text-center mb-3 text-muted chariot-footer">
      Copyright 2020-2024 Cirrus Link Solutions
    </div>
    <BOverlay
      :show="globalLoading"
      opacity="0.8"
      z-index="10000"
      no-center
      small
      no-wrap
      type="grow"
      spinner-variant="primary">
        <template #overlay>
          <div class="text-center chariot-overlay-message">
            <h3 class="text-primary">{{ globalLoadingText }}</h3>
            <BSpinner variant="primary" type="grow" label="Spinning"></BSpinner>
          </div>
        </template>
      </BOverlay>
  </div>
</template>
<script>
import {
  BContainer,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue';
import SideMenu from '@/components/SideMenu';
import LicenseBanner from '@/components/LicenseBanner';

/**
 * Main app entry navigation and routing occurs here
 */
export default {
  name: 'App',
  components: {
    BContainer,
    BOverlay,
    BSpinner,
    SideMenu,
    LicenseBanner,
  },
  data() {
    return {
      showNav: false,
      lockedNav: true,
      fullscreen: false,
      showLicenseBanner: false,
      showFooter: false,
      disableTransition: true,
      globalLoading: false,
      globalLoadingText: 'Downloading',
    };
  },
  mounted() {
    this.$root.$on('show-global-loading', this.showGlobalLoading);
    this.$root.$on('hide-global-loading', this.hideGlobalLoading);
  },
  methods: {
    /**
     * Before leave transition will clear the nav bar to prevent flickering
     * Will prevent nav flicker when transitioning between pages with toolbar
     */
    beforeLeave() {
      if (this.$route.meta.showNav !== this.showNav) {
        this.showNav = false;
      }
    },
    showGlobalLoading({ loadingText }) {
      document.body.classList.add('global-loading');
      this.globalLoading = true;
      this.globalLoadingText = loadingText || 'Downloading';
    },
    hideGlobalLoading() {
      document.body.classList.remove('global-loading');
      this.globalLoading = false;

      // Fallback to generic message
      this.globalLoadingText = 'Downloading';
    },
    setNavState(locked) {
      this.lockedNav = locked;
    },
    /**
     * Sets page to the `appropriate nav state when a transition enters
     */
    beforeEnter() {
      this.showNav = this.$route.meta.showNav;
      this.showFooter = this.$route.meta.showFooter;
      // eslint-disable-next-line no-unneeded-ternary
      this.fullscreen = this.$route.meta.fullscreen ? true : false;
    },

    afterEnter() {
      this.disableTransition = false;
    },

    logout() {
      const apiService = this.generateRestCall();
      localStorage.removeItem('chariotSession');
      this.$router.push({ name: 'Login' });

      apiService.post('/logout').then(() => {
        this.$store.dispatch('clearUserDetails');
      })
        .catch((error) => {
          this.sendNotification(this.$t('notifications.chariotTitle'), error.response.data.message, 'danger');
        });
    },
  },
  watch: {
    // Watch route to prevent transition problems
    $route(to, from) {
      if (from.name === 'Login' || from.name === null) {
        this.disableTransition = true;
      } else {
        this.disableTransition = false;
      }
    },
  },
};
</script>
<style lang="scss">
  @import "~bootstrap/scss/bootstrap.scss";
  @import "scss/main.scss";
  @import "~bootstrap-vue/src/index.scss";

  .chariot-overlay-message {
    position: fixed;
    top:50%;
    left: 50%;
    height: 80px;
    width: 500px;
    margin-top: -40px; /* Negative half of height. */
    margin-left: -250px; /* Negative half of width. */
  }

  .fullscreen {
    margin: 0 !important;
    padding: 0;
    width: 100%;
  }
</style>
