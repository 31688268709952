<template>
  <nav id="chariotSideBar" :class="[{ 'sidebar-toggled': locked }]">
    <div class="sidebar-body">
      <ul class="sidebar-nav h-100">
        <li class="sidebar-user-info mb-2 p-0">
          <BDropdown
            variant="link"
            boundary="viewport"
            right
            toggle-class="text-decoration-none p-0">
            <template slot="button-content">
              <div class="media text-left mt-0 py-0">
                <img rel="preload" class="mt-4 mr-3" width="50" height="30" alt="Cirrius Logo" :src="require('@/assets/charioticon.png')">
                <div class="media-body sidebar-item-text mt-3">
                  <h5 class="mb-0 mt-2 text-light">Chariot</h5>
                  <span class="text-light">{{ $store.state.username }}</span>
                  <BIconCaretDown class="sidebar-header-arrow" />
                </div>
              </div>
            </template>
            <BDropdownHeader class="sidebar-header text-center">
              <BMedia class="mt-1">
                <template v-slot:aside>
                  <BAvatar variant="primary" size="md" />
                </template>
                <h5 class="my-0">{{ $store.state.username }}</h5>
                <span class="text-muted">{{ $store.state.email || 'No Email' }}</span>
              </BMedia>
            </BDropdownHeader>
            <BDropdownItem @click="editProfile()">
              <BIconPencil class="ml-2 mr-3" /> {{$t("sidemenu.editProfile")}}
            </BDropdownItem>
            <BDropdownItem v-b-modal.showEulaModal>
              <BIconNewspaper class="ml-2 mr-3" /> EULA
            </BDropdownItem>
            <BDropdownItem :href="$store.state.helpUrl" target="_blank">
              <BIconQuestionCircle class="ml-2 mr-3" /> {{$t("sidemenu.help")}}
            </BDropdownItem>
            <BDropdownDivider class="m-0"/>
            <BDropdownItem @click="logout()">
              <BIconBoxArrowDownLeft class="ml-2 mr-3" />{{$t("sidemenu.signout")}}
            </BDropdownItem>
          </BDropdown>
        </li>
        <li class="sidebar-item-subheader">
          <h6>{{$t("sidemenu.status")}}</h6>
        </li>
        <li>
          <RouterLink :to="{ name: 'Dashboard'}">
            <BIconGrid1x2 class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.dashboard")}}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="{ name: 'Logging'}">
            <BIconBarChart class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.logging")}}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="{ name: 'Sparkplug'}">
            <BIconLightning class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.sparkplug")}}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="{ name: 'MQTTStatus'}">
            <BIconScrewdriver class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.mqttClients")}}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="{ name: 'Alerts'}">
            <BIconBell class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.alerts")}}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="{ name: 'Diagnostics'}">
            <BIconClipboardData class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.diagnostics")}}</span>
          </RouterLink>
        </li>
        <li class="sidebar-item-subheader">
          <h6>{{$t("sidemenu.configuration")}}</h6>
        </li>
        <li>
          <RouterLink :to="{ name: 'Accounts'}">
            <BIconPeople class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.accounts")}}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="{ name: 'MQTTCredentials'}">
            <BIconCardText class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.mqttCredentials")}}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="{ name: 'MQTTServer'}">
            <BIconServer class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.mqttServer")}}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="{ name: 'License'}">
            <BIconStopwatch class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.license")}}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink :to="{ name: 'System'}">
            <BIconLaptop class="mr-3" />
            <span class="sidebar-item-text">{{$t("sidemenu.system")}}</span>
          </RouterLink>
        </li>
      </ul>
      <div class="sidebar-bottom">
        <a
          @click.prevent="toggle()"
          href="#">
          <BIconArrowLeft v-if="locked" />
          <BIconArrowRight v-else />
        </a>
      </div>
    </div>
    <BModal
      :title="$t('sidemenu.eulaTitle')"
      header-bg-variant="primary"
      header-text-variant="light"
      ok-only
      size="lg"
      id="showEulaModal">
      <EULAText />
    </BModal>
  </nav>
</template>

<script>
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BDropdownHeader,
  BIconArrowRight,
  BIconArrowLeft,
  BIconBell,
  BIconBarChart,
  BIconBoxArrowDownLeft,
  BIconCaretDown,
  BIconGrid1x2,
  BIconLightning,
  BIconPeople,
  BIconPencil,
  BIconServer,
  BIconCardText,
  BIconScrewdriver,
  BIconStopwatch,
  BIconLaptop,
  BIconNewspaper,
  BIconQuestionCircle,
  BMedia,
  BIconClipboardData,
} from 'bootstrap-vue';
import EULAText from '@/components/EULAText';

/**
 * Controls functional and visual display of the application side menu
 */
export default {
  name: 'Side-Menu',
  components: {
    BAvatar,
    BDropdown,
    BDropdownDivider,
    BDropdownHeader,
    BDropdownItem,
    BIconArrowRight,
    BIconArrowLeft,
    BIconBarChart,
    BIconBell,
    BIconBoxArrowDownLeft,
    BIconCaretDown,
    BIconGrid1x2,
    BIconLightning,
    BIconPencil,
    BIconPeople,
    BIconServer,
    BIconCardText,
    BIconStopwatch,
    BIconScrewdriver,
    BIconLaptop,
    BIconNewspaper,
    BIconQuestionCircle,
    BMedia,
    BIconClipboardData,
    EULAText,
  },
  props: {
    isLocked: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
      default: () => ({
        name: 'Chariot User',
        company: 'Chariot Company',
        email: 'fake@chariot.com',
      }),
    },
  },
  data() {
    return {
      locked: this.isLocked,
    };
  },
  methods: {
    /**
     * Controls expanding / contracting the side menu
     */
    toggle() {
      this.locked = !this.locked;
      this.$emit('locked', this.locked);
    },
    // Direct user to edit their specific profile
    // To avoid issues with the store cleaning up using a function rather then a :to
    editProfile() {
      this.$router.push({ name: 'EditUser', params: { username: this.$store.state.username } });
    },
    /**
     * Logout of the current session. Also clears up the store for future login occurences
     * in the same application session
     */
    logout() {
      this.$emit('logout');
    },
  },
};
</script>

<style lang="scss" scoped>
#chariotSideBar {
  position: fixed;
  width: 4em;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 500;

  .sidebar-body {
    display: flex;
    transition: 0.4s width ease;
    flex-direction: column;
    overflow-x: hidden;
    white-space: nowrap;
    top: 0;
    width: 4em;
    height: 100%;
    margin-left: 0;
    overflow: visible;
    background: $sidemenu-bg;
    z-index: 950;
    border-right: 1px solid $gray-700;

    .sidebar-item-subheader {
      display: none;

      h6  {
        color: $light;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        opacity: 0.6;
        margin: 5px 0 5px 0;
      }
    }

    .sidebar-bottom {
      background: $sidemenu-bg;
      z-index: 200;
      font-size: 16px;
      width: 100%;
      bottom: 0;
      left: 0;
      border-top: 1px solid $gray-700;

      a {
        display: inline-block;
        padding: 10px 0 10px 0;
        border-radius: 0;
        padding-left: 20px;
        color: white;
        width: 100%;
        height: 100%;

        &:hover {
          color: $sidemenu-text-color;
        }

        svg {
          font-size: 14px;
          transition: 0.4s margin ease;
        }
      }
    }

    .sidebar-item-text {
      display: none;
      opacity: 0;
    }

    .sidebar-nav {
        flex: 1;
        top: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        & > li {
          overflow: hidden;
          padding:4px 7px 4px 6px;

          & > a {
            color: $sidemenu-text-color;
            display: block;
            position: relative;
            text-decoration: none;
            padding: 10px 20px 10px 16px;
            border-radius: 3.5px;

            &:hover {
                background: $sidemenu-hover;
                color: $sidemenu-text-color;
            }

            &.router-link-active {
                color: $sidemenu-text-color;
                background: $sidemenu-hover;
            }
          }
        }

      ::v-deep {
        .b-dropdown {
          width: 100%;
        }

        .dropdown-header {
              padding: 1rem 1rem;
        }

        .sidebar-user-info {
          border-bottom: 1px solid $gray-700;
          height: 70px;

          .dropdown-toggle {
              padding-left: 12px;
              color: $sidemenu-text-color;
              font-size: .875rem;

              &:after {
                display: none;
              }

              .b-avatar {
                margin-left: 12px;
                margin-right: 12px;
              }

              .sidebar-header-arrow {
                position: relative;
                float: right;
                margin-right: 5px;
                top: 15px;
              }

              h5 {
                  font-size: inherit;
              }
          }
        }
      }
    }
  }

  &.sidebar-toggled {
    width: 15em;
    .sidebar-body {
      margin-left: 0;
      width: 15em;

      .sidebar-nav {
        width: 100%;

        .sidebar-item-subheader {
          display: block;
        }

        .sidebar-item-text {
            display: inline;
            opacity: 1;
        }

        > li > ul {
            display: block;
        }
      }

      .sidebar-bottom {
        svg {
          margin-left: 160px;
        }
      }
    }
  }
}
</style>
