import 'core-js/stable';
import 'intersection-observer';
import 'regenerator-runtime/runtime';
import { has } from 'lodash';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import Vue from 'vue';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import VueClipboard from 'vue-clipboard2';
import panZoom from 'vue-panzoom';
import restService from '@/api/restService';
import store from './store';
import router from './router';
import i18n from './i18n';
import App from './App';

Vue.config.productionTip = false;

// Toast/notification system globally accessible
Vue.use(ToastPlugin);
// Modal management global
Vue.use(ModalPlugin);
// Countdown plugin
Vue.use(vueAwesomeCountdown, 'vac');
// Add clipboard copy capability
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(panZoom);
/**
 * Global route guard check for pages that require authentication, this ensures a token is available and the baseline
 * user details exist for the application to function.
 * If these don't exist it will redirect back to login
 *
 * Route guard event order:
 * Component beforeRouteLeave
 * Global beforeEach
 * Component beforeRouteUpdate
 * Global beforeEnter
 * --- Async Resolution ---
 * Component beforeRouteEnter
 * Global beforeResolve
 * --- Navigation Complete ---
 * Global afterEach
 * --- Dom Renders ---
 * Callback if any provided to beforeRouterEnter next()
 */
router.beforeEach((to, from, next) => {
  if (has(to, 'meta.noAuth') && to.meta.noAuth === true) {
    // No auth required then continue to route
    next();
  } else if (store.state.authToken !== null && store.state.username !== null && store.state.accountId !== null) {
    // Authtoken, username, and accountId are all stored, then continue to route
    next();
  } else {
    // If missing authtoken/username/accountId attempt to gather data effectively
    let sessionCheck = localStorage.getItem('chariotSession');

    // No local storeage session send to login
    if (sessionCheck === null) {
      next({ path: '/' });
    } else {
      // Parse local storage for needed user session details
      sessionCheck = JSON.parse(sessionCheck);
      store.dispatch('setToken', sessionCheck.access_token);
      store.dispatch('setUsername', sessionCheck.username);

      const apiService = restService.generateRestCall();
      // Assuming the token contains required username, finish gathering the users information
      if (store.state.username !== null) {
        apiService.get(`/users/${store.state.username}`).then((userDetails) => {
          store.dispatch('setUserDetails', userDetails.data);
          next();
        })
          .catch((error) => {
            if (error.response.status === 404) {
              store.dispatch('setUserDetails', {
                username: store.state.username,
              });
              next();
            } else {
              // Expired token from another session, requires cleanup
              localStorage.removeItem('chariotSession');
              next({ path: '/' });
            }
          });
      } else {
        // Bad token with no username send to login
        localStorage.removeItem('chariotSession');
        next({ path: '/' });
      }
    }
  }
});

/**
 * Global Vue mixins for universally available functions
 */
Vue.mixin({
  methods: {
    generateRestCall(params) {
      return restService.generateRestCall(params);
    },
    /**
     * Allows for application wide notifications
     *
     * @param String title - Title for notification header
     * @param String message - Body of the notification
     * @param String type - Sets the variant type of header. Available types include
     * default, primary, seconday, danger, warning, success and info.
     */
    sendNotification(title, message, type) {
      this.$root.$bvToast.toast(message, {
        title,
        variant: type,
        autoHideDelay: 5000,
        appendToast: true,
        toaster: 'b-toaster-top-center',
      });
    },
    /**
     * Provides an application wide function to validate the state of an input when validation is provided
     *
     * @param String name - Name of input type
     */
    validateState(validator) {
      const { $dirty, $error } = validator;
      let validatationState = $dirty ? !$error : null;

      if (validatationState === true) {
        validatationState = null;
      }

      return validatationState;
    },
  },
});

/**
 * Application bootstrap
 */
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
