/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import router from '@/router';
import store from '@/store';

export default {
  generateRestCall(overrides = {}) {
    const requestDetails = {
      baseURL: store.state.serverUrl,
      headers: {
        Accept: 'application/json;api-version=1.0',
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store',
      },
      ...overrides,
    };

    if (store.state.authToken !== null) {
      requestDetails.headers.Authorization = `bearer ${store.state.authToken}`;
    }

    const axiosInstance = axios.create(requestDetails);

    axiosInstance.interceptors.response.use((response) => response, (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('chariotSession');
        store.dispatch('clearUserDetails');

        if (router.currentRoute.name !== 'Login') {
          router.push({ path: '/login' });
        }

        return Promise.reject(error);
      }

      if (error.response === undefined) {
        // In the case of critical error
        return Promise.reject(new Error(error.message));
      }

      return Promise.reject(error);
    });

    return axiosInstance;
  },
};
