import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

/**
 * Controls the routes available for the entire application
 */
const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    name: 'Alerts',
    path: '/alerts',
    component: () => import(/* webpackChunkName: "alerts" */ '../views/Alerts'),
    meta: { showNav: true },
  },
  {
    name: 'Diagnostics',
    path: '/diagnostics',
    component: () => import(/* webpackChunkName: "alerts" */ '../views/Diagnostics'),
    meta: { showNav: true },
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    meta: { showNav: true },
  },
  {
    name: 'EULA',
    path: '/eula',
    component: () => import(/* webpackChunkName: "eula" */ '../views/EULA'),
    meta: { noAuth: true },
  },
  {
    name: 'License',
    path: '/license',
    component: () => import(/* webpackChunkName: "license" */ '../views/License'),
    meta: { showNav: true },
  },
  {
    name: 'Logging',
    path: '/logging',
    component: () => import(/* webpackChunkName: "logging" */ '../views/Logging'),
    meta: { showNav: true },
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
    meta: { noAuth: true, showFooter: true },
  },
  {
    name: 'MQTTStatus',
    path: '/mqttstatus',
    component: () => import(/* webpackChunkName: "mqtt" */ '../views/MQTT/Status'),
    meta: { showNav: true },
  },
  {
    name: 'MQTTCredentials',
    path: '/mqttcredentials',
    component: () => import(/* webpackChunkName: "mqtt" */ '../views/MQTT/Authentication'),
    meta: { showNav: true },
  },
  {
    name: 'EditMQTTCredentials',
    path: '/mqttcredentials/:credential',
    component: () => import(/* webpackChunkName: "mqtt" */ '../views/MQTT/Authentication/Credentials/EditCredentialsDetails'),
    meta: { showNav: true },
  },
  {
    name: 'MQTTServer',
    path: '/mqttserver',
    component: () => import(/* webpackChunkName: "mqtt" */ '../views/MQTT/Server'),
    meta: { showNav: true },
  },

  {
    path: '/roles/:role',
    name: 'EditRole',
    component: () => import(/* webpackChunkName: "roles" */ '../views/Roles/EditRoleDetails'),
    meta: { showNav: true },
  },
  {
    path: '/sparkplug',
    name: 'Sparkplug',
    component: () => import(/* webpackChunkName: "sparkplug" */ '../views/Sparkplug'),
    meta: { showNav: true },
  },
  {
    path: '/system',
    name: 'System',
    component: () => import(/* webpackChunkName: "system" */ '../views/System'),
    meta: { showNav: true },
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: () => import(/* webpackChunkName: "users" */ '../views/Accounts'),
    meta: { showNav: true },
  },
  {
    path: '/users/:username',
    name: 'EditUser',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users/EditUserDetails'),
    meta: { showNav: true },
  },
  {
    path: '/source/:sourceName',
    name: 'EditSource',
    component: () => import(/* webpackChunkName: "users" */ '../views/Sources/EditDetails'),
    meta: { showNav: true },
  },
  {
    path: '/bridgeclient/:bridgeclient',
    name: 'EditBridgeClients',
    component: () => import(/* webpackChunkName: "bridgeclients" */ '../views/MQTT/Server/BridgeClients/EditBridgeClients'),
    meta: { showNav: true },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "login" */ '../views/NotFound'),
    meta: { showNav: false },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
