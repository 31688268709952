var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      class: [{ "sidebar-toggled": _vm.locked }],
      attrs: { id: "chariotSideBar" },
    },
    [
      _c("div", { staticClass: "sidebar-body" }, [
        _c("ul", { staticClass: "sidebar-nav h-100" }, [
          _c(
            "li",
            { staticClass: "sidebar-user-info mb-2 p-0" },
            [
              _c(
                "BDropdown",
                {
                  attrs: {
                    variant: "link",
                    boundary: "viewport",
                    right: "",
                    "toggle-class": "text-decoration-none p-0",
                  },
                },
                [
                  _c("template", { slot: "button-content" }, [
                    _c("div", { staticClass: "media text-left mt-0 py-0" }, [
                      _c("img", {
                        staticClass: "mt-4 mr-3",
                        attrs: {
                          rel: "preload",
                          width: "50",
                          height: "30",
                          alt: "Cirrius Logo",
                          src: require("@/assets/charioticon.png"),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "media-body sidebar-item-text mt-3" },
                        [
                          _c("h5", { staticClass: "mb-0 mt-2 text-light" }, [
                            _vm._v("Chariot"),
                          ]),
                          _c("span", { staticClass: "text-light" }, [
                            _vm._v(_vm._s(_vm.$store.state.username)),
                          ]),
                          _c("BIconCaretDown", {
                            staticClass: "sidebar-header-arrow",
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "BDropdownHeader",
                    { staticClass: "sidebar-header text-center" },
                    [
                      _c(
                        "BMedia",
                        {
                          staticClass: "mt-1",
                          scopedSlots: _vm._u([
                            {
                              key: "aside",
                              fn: function () {
                                return [
                                  _c("BAvatar", {
                                    attrs: { variant: "primary", size: "md" },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c("h5", { staticClass: "my-0" }, [
                            _vm._v(_vm._s(_vm.$store.state.username)),
                          ]),
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              _vm._s(_vm.$store.state.email || "No Email")
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "BDropdownItem",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.editProfile()
                        },
                      },
                    },
                    [
                      _c("BIconPencil", { staticClass: "ml-2 mr-3" }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("sidemenu.editProfile")) + " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "BDropdownItem",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.showEulaModal",
                          modifiers: { showEulaModal: true },
                        },
                      ],
                    },
                    [
                      _c("BIconNewspaper", { staticClass: "ml-2 mr-3" }),
                      _vm._v(" EULA "),
                    ],
                    1
                  ),
                  _c(
                    "BDropdownItem",
                    {
                      attrs: {
                        href: _vm.$store.state.helpUrl,
                        target: "_blank",
                      },
                    },
                    [
                      _c("BIconQuestionCircle", { staticClass: "ml-2 mr-3" }),
                      _vm._v(" " + _vm._s(_vm.$t("sidemenu.help")) + " "),
                    ],
                    1
                  ),
                  _c("BDropdownDivider", { staticClass: "m-0" }),
                  _c(
                    "BDropdownItem",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.logout()
                        },
                      },
                    },
                    [
                      _c("BIconBoxArrowDownLeft", { staticClass: "ml-2 mr-3" }),
                      _vm._v(_vm._s(_vm.$t("sidemenu.signout")) + " "),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c("li", { staticClass: "sidebar-item-subheader" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("sidemenu.status")))]),
          ]),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "Dashboard" } } },
                [
                  _c("BIconGrid1x2", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.dashboard"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "Logging" } } },
                [
                  _c("BIconBarChart", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.logging"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "Sparkplug" } } },
                [
                  _c("BIconLightning", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.sparkplug"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "MQTTStatus" } } },
                [
                  _c("BIconScrewdriver", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.mqttClients"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "Alerts" } } },
                [
                  _c("BIconBell", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.alerts"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "Diagnostics" } } },
                [
                  _c("BIconClipboardData", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.diagnostics"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("li", { staticClass: "sidebar-item-subheader" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("sidemenu.configuration")))]),
          ]),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "Accounts" } } },
                [
                  _c("BIconPeople", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.accounts"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "MQTTCredentials" } } },
                [
                  _c("BIconCardText", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.mqttCredentials"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "MQTTServer" } } },
                [
                  _c("BIconServer", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.mqttServer"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "License" } } },
                [
                  _c("BIconStopwatch", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.license"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "RouterLink",
                { attrs: { to: { name: "System" } } },
                [
                  _c("BIconLaptop", { staticClass: "mr-3" }),
                  _c("span", { staticClass: "sidebar-item-text" }, [
                    _vm._v(_vm._s(_vm.$t("sidemenu.system"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "sidebar-bottom" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toggle()
                },
              },
            },
            [_vm.locked ? _c("BIconArrowLeft") : _c("BIconArrowRight")],
            1
          ),
        ]),
      ]),
      _c(
        "BModal",
        {
          attrs: {
            title: _vm.$t("sidemenu.eulaTitle"),
            "header-bg-variant": "primary",
            "header-text-variant": "light",
            "ok-only": "",
            size: "lg",
            id: "showEulaModal",
          },
        },
        [_c("EULAText")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }