<template>
    <div v-show="!hideBanner && licenseMode === 'NORMAL'" :class="['chariot-temporary-license-banner', dynamicBannerClass]">
      <div class="align-self-center m-auto text-nowrap">
        <b class="mr-3">{{ licenseText }}</b>
        <vac
          v-if="licenseState === 'TRIAL'"
          class="chariot-license-timer"
          :auto-start="false"
          ref="bannerTimer"
          @finish="stopLicense"
          :left-time="trialTimer">
            <span
              slot="process"
              slot-scope="{ timeObj }">{{ `${timeObj.h}:${timeObj.m}:${timeObj.s}` }}</span>
        </vac>
      </div>
      <BButton @click="startLicense" v-if="licenseState !== 'TRIAL'" class="chariot-refresh align-self-right m-2" variant="outline-danger">
        <BIconClock class="mr-2" />{{ $t("license.startLicense") }}
      </BButton>
    </div>
</template>

<script>
import {
  BButton,
  BIconClock,
} from 'bootstrap-vue';
import { mapState } from 'vuex';
/**
 * License status widget
 */
export default {
  name: 'License-Banner',
  components: {
    BButton,
    BIconClock,
  },
  data() {
    return {
      licenseText: this.$t('license.noActiveLicense'),
      trialTimer: 999999999,
      dynamicBannerClass: '',
      hideBanner: true,
    };
  },
  created() {
    this.apiService = this.generateRestCall();

    this.apiService.get('/license').then((licenseDetails) => {
      this.trialTimer = (licenseDetails.data.trialTimer > 0) ? licenseDetails.data.trialTimer : 0;
      this.$store.dispatch('setLicenseState', licenseDetails.data.state);
      this.$store.dispatch('setLicenseMode', licenseDetails.data.mode);
    })
      .catch(() => {
        this.sendNotification(this.$t('notifications.license.licenseTitle'), this.$t('notifications.license.failedToGetInformation'), 'danger');
      });
  },
  methods: {
    startLicense() {
      this.apiService.post('/license?action=start-trial-timer').then((licenseDetails) => {
        this.$store.dispatch('setLicenseState', licenseDetails.data.state);
        this.$store.dispatch('setLicenseMode', licenseDetails.data.mode);
        this.$store.dispatch('setServerActive', true);
        this.trialTimer = licenseDetails.data.trialTimer;
      })
        .catch(() => {
          this.sendNotification(this.$t('notifications.license.licenseTitle'), this.$t('notifications.license.failedStartTrial'), 'danger');
        });
    },
    stopLicense() {
      this.$store.dispatch('setLicenseState', 'INACTIVE');
    },
    checkTimer() {
      this.$nextTick(() => {
        if (this.$refs.bannerTimer) {
          this.$refs.bannerTimer.stopCountdown();
        }
      });
    },
    generateBannerClass(licenseState) {
      switch (licenseState) {
        case 'EXPIRED':
        case 'INACTIVE':
          this.dynamicBannerClass = 'bg-danger text-light';
          this.licenseText = this.$t('license.noActiveLicense');
          this.hideBanner = false;

          this.$nextTick(() => {
            if (this.$refs.bannerTimer) {
              this.$refs.bannerTimer.stopCountdown();
            }
          });

          break;
        case 'TRIAL':
          this.dynamicBannerClass = 'bg-warning';
          this.licenseText = this.$t('license.trialActive');
          this.hideBanner = false;

          this.$nextTick(() => {
            if (this.$refs.bannerTimer) {
              this.$refs.bannerTimer.startCountdown(true);
            }
          });

          break;
        default:
          this.dynamicBannerClass = 'd-none';
          this.$nextTick(() => {
            if (this.$refs.bannerTimer) {
              this.$refs.bannerTimer.stopCountdown();
            }
          });
          this.hideBanner = true;
      }
    },
  },
  computed: {
    ...mapState([
      'licenseState',
      'licenseMode',
    ]),
  },
  watch: {
    licenseState(state) {
      this.generateBannerClass(state);
    },
  },
};
</script>
<style lang="scss" scoped>
  .chariot-temporary-license-banner {
    min-width: 250px;
    height: 45px;
    display: flex;

    .chariot-license-timer {
      display: inline-block;
      width: 80px;
    }

    .chariot-refresh {
      color: $white;
      border-color: $white;

      &:hover {
        background-color: darken($danger, 5%);
      }

      &:active {
        border-color: $white !important;
        background-color: darken($danger, 5%) !important;
      }
    }
  }
</style>
